<template>
  <v-container fluid>
    <!--HEADER-->
    <v-app-bar :color=colorMenu flat app dense height="40px" dark>
      <v-btn icon :to="{ name: complejo}">
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-toolbar-title>{{tituloHeader}}</v-toolbar-title>
      <v-spacer></v-spacer>
    
    </v-app-bar>
    <v-text v-html="datosObtenidos[0].contenido">
    </v-text>        
  </v-container>
</template>
<script>

import axios from 'axios'
export default {
  name:'normascomplejo',
  props: {
          tituloHeader: String,
          idcomplejo:Number,
          complejo: String,
          colorMenu:String,
  },
  data() {
    return {
            
      datosObtenidos:[],
    };
  },
  created:
           async function() {              
               await axios.get(process.env.VUE_APP_API +'/complejo/normas',
                 {params:{
                   idComplejo: this.idcomplejo
                    }
          })
       .then((response) => {
                          this.datosObtenidos= response.data
      })

  },
  computed: {},
  methods: {
    
  }
};
</script>

